import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    [
      _c(VCardTitle, [
        _c("h4", { staticClass: "dxa_modal_title h4" }, [
          _vm._v(" " + _vm._s(_vm.$tc("manage_financial_field", 1)) + " "),
        ]),
      ]),
      _c(
        VCardText,
        [
          _c(
            VForm,
            {
              ref: "form",
              staticClass: "mt-5",
              on: {
                submit: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.add_analysis()
                },
              },
            },
            [
              !_vm.loading
                ? _c(
                    "div",
                    { staticClass: "mx-2" },
                    [
                      _c(VSelect, {
                        attrs: {
                          items: _vm.IndexOptions,
                          label: _vm.$t("index"),
                          outlined: "",
                        },
                        model: {
                          value: _vm.analysis.Index,
                          callback: function ($$v) {
                            _vm.$set(_vm.analysis, "Index", $$v)
                          },
                          expression: "analysis.Index",
                        },
                      }),
                      _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            { attrs: { cols: "2" } },
                            [
                              _c(VSelect, {
                                attrs: {
                                  items: _vm.availableLangs,
                                  label: _vm.$t("language"),
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.titleSelectedLang,
                                  callback: function ($$v) {
                                    _vm.titleSelectedLang = $$v
                                  },
                                  expression: "titleSelectedLang",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { cols: "10" } },
                            [
                              _c(VTextField, {
                                attrs: { label: _vm.$t("title") },
                                model: {
                                  value:
                                    _vm.analysis.Title[_vm.titleSelectedLang],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.analysis.Title,
                                      _vm.titleSelectedLang,
                                      $$v
                                    )
                                  },
                                  expression:
                                    "analysis.Title[titleSelectedLang]",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            { attrs: { cols: "2" } },
                            [
                              _c(VSelect, {
                                attrs: {
                                  items: _vm.availableLangs,
                                  label: _vm.$t("language"),
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.textSelectedLang,
                                  callback: function ($$v) {
                                    _vm.textSelectedLang = $$v
                                  },
                                  expression: "textSelectedLang",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { cols: "10" } },
                            [
                              _c(VTextField, {
                                attrs: { label: _vm.$t("text") },
                                model: {
                                  value:
                                    _vm.analysis.Text[_vm.textSelectedLang],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.analysis.Text,
                                      _vm.textSelectedLang,
                                      $$v
                                    )
                                  },
                                  expression: "analysis.Text[textSelectedLang]",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    VRow,
                    { attrs: { justify: "center" } },
                    [
                      _c(VProgressCircular, {
                        attrs: {
                          indeterminate: "",
                          size: "70",
                          color: "primary",
                        },
                      }),
                    ],
                    1
                  ),
              _c(
                VCardActions,
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "dxa_modal_btnSuccess",
                      attrs: {
                        color: "primary",
                        "min-width": "100",
                        type: "submit",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("save")))]
                  ),
                  _c(
                    VBtn,
                    {
                      staticClass: "dxa_modal_btnError",
                      attrs: { color: "red", "min-width": "100" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("cancel")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.error
            ? _c(VAlert, { staticClass: "ma-2", attrs: { type: "error" } }, [
                _vm._v(_vm._s(_vm.$t(_vm.error))),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }